import React, { useReducer, useRef, useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import axios from 'axios'
import { Oval } from 'react-loader-spinner'
import { useGlobalState } from '../hooks/useInitialization.js';
import useAuth from '../hooks/useAuth.js';

const AppContext = React.createContext();

const initialState = false

const reducer = (state, action) => {
    switch (action.type) {
        case 'CONNECTED':
            return {
                ...state,
                isConnected: action.payload,
            }
        case 'ranking':
            return {
                ...state,
                ranking: action.payload
            }
        case 'sections':
            return {
                ...state,
                sections: action.payload
            }
        case 'notifications':
            return {
                ...state,
                notifications: action.payload,
            }
        case 'updateNotifications':
            console.log(action.payload)
            if (action.payload.type === 'read_at') {
                return {
                    ...state,
                    notifications: state.notifications.map(notification =>
                        notification.id === action.payload.id
                            ? { ...notification, read_at: action.payload.date }
                            : notification
                    )
                };
            } else if (action.payload.type === 'hidden_at') {
                console.log(action.payload)
                return {
                    ...state,
                    notifications: state.notifications.filter(notification =>
                        notification.id !== action.payload.id
                    )
                };
            } else {
                return {
                    ...state,
                    notifications: state.notifications.map(notification =>
                        notification.id === action.payload.id
                            ? { ...notification, viewed_at: action.payload.date }
                            : notification
                    )
                };
            }
        case 'notificationsCounter':
            return {
                ...state,
                notificationsCounter: action.payload,
            }
        case 'updatePageDataItem':
            return {
                ...state,
                updatePageDataItem: (state.updatePageDataItem || 0) + 1,
            }
        default:
            return state;
    }
};

const AppProvider = ({ children }) => {
    const { dataApplication } = useGlobalState();
    const [appState, dispatch] = useReducer(reducer, initialState);
    const { user, signOut, dispatch: authDispatch } = useAuth();
    const [socket, setSocket] = useState(null);
    const isDebouncing = useRef(false);
    const scrollContainerRef = useRef(null);
    const timerRef = useRef(null);
    const [updateNotifications, setUpdateNotifications] = useState(0)
    const [updatePageDataNetworking, setUpdatePageDataNetworking] = useState({ count: 0, data: {} })
    const [modalConfirmation, setModalConfirmation] = useState(false);
    const [confirmationProps, setConfirmationProps] = useState({});
    const [clearNotification, setClearNotification] = useState(0)
    const [totalContacts, setTotalContacts] = useState({ total_available: 0, total_contacts: 0, total_invites: 0 })
    const [clearNotificationID, setClearNotificationID] = useState(0)
    // eslint-disable-next-line no-unused-vars
    const [loading, setLoading] = useState(false);

    const handleExit = () => {
        setLoading(true)
        axios.post(`/api/logout`, {}, {
            withCredentials: true
        }).then(function (response) {
            setTimeout(() => {
                signOut()
                setLoading(false)
            }, 250);
        }).catch(function (error) {
            console.log(error)
        })
    }

    useEffect(() => {
        if (dataApplication && user && !appState.CONNECTED) {
            console.log('started connection')
            const newSocket = io(dataApplication.customizacao.server.eventosSocketAPI + '?event_id=' + dataApplication.event_id + '&user_id=' + user.user_id, {
                autoConnect: true,
            });
            setSocket(newSocket);
            newSocket.on('login', () => {
                console.log('connect')
                dispatch({ type: 'CONNECTED', payload: true });
            });
            newSocket.on('disconnect', () => {
                console.log('disconnect')
                dispatch({ type: 'CONNECTED', payload: false });
            });
            //Events
            newSocket.on('eventInterest', () => {
                console.log('eventInterest')
            });
            //Networking
            newSocket.on('friendRequest', (result) => {
                if (result.id) {
                    setUpdatePageDataNetworking(prevState => ({
                        ...prevState,
                        count: prevState.count + 1,
                        data: result
                    }));
                }
            });
            //Ranking
            newSocket.on('getRanking', (result) => {
                console.log(result)
                authDispatch({ type: "UPDATE_USER", payload: { 'rank_position': result.rank_position, 'total_value': result.total_value } });
            });
            //Notifications
            newSocket.on('updateNotifications', (result) => {
                console.log('updateNotifications')
                setUpdateNotifications((prevState) => prevState + 1)
            });
            newSocket.on('subscribe', (result) => {
                console.log(result)
                authDispatch({ type: "UPDATE_USER", payload: { push_subscription: result } });
            });

            newSocket.on('editNotification', (result) => {
                console.log(result)
                console.log('updateNotifications')
                if (result !== true) {
                    dispatch({ type: 'updateNotifications', payload: result });
                }
                dispatch({ type: 'notificationsCounter', payload: undefined });
            });
            newSocket.emit('login');

            dispatch({ type: 'ranking', payload: { 'rank_position': user.rank_position, 'total_value': user.total_value } });
            // Ensure dataApplication.customizacao.sections is initialized properly
            // Ensure dataApplication.customizacao.sections is initialized properly
            // Ensure dataApplication.customizacao.sections is initialized properly
            // Ensure dataApplication.customizacao.sections is initialized properly
            let currentSections = dataApplication.customizacao.sections;
            let sections = { sidebar: [], footer: [], sections: [] };

            // Add all items to the sidebar
            sections.sidebar = [...currentSections];

            // Add items to the footer if displayOnFooter is true
            sections.footer = currentSections.filter(item => item.displayOnFooter);

            // Remove the "lead" section from both sidebar and footer if user.type !== 1
            if (user.type !== 1) {
                sections.sidebar = sections.sidebar.filter(item => item.href !== "/coleta-de-lead");
                sections.footer = sections.footer.filter(item => item.href !== "/coleta-de-lead");
            }

            // Ensure that the home object "/" is always first in the footer if it exists
            sections.footer = sections.footer.filter(item => item.href !== "/");
            const homeItem = additionalSections.find(item => item.href === "/");
            if (homeItem) {
                sections.footer.unshift(homeItem);
            }

            // Add the additional object with href '/menu' to the footer only if there are multiple items in the sidebar
            if (sections.sidebar.length > 1) {
                const menuItem = additionalSections.find(item => item.href === "/menu");
                if (menuItem) {
                    sections.footer.push(menuItem);
                }
            }

            // Insert the QR Code into the middle of the footer or at the end if there are only two items
            if (!sections.footer.some(item => item.href === "/qrcode")) {
                const qrcodeItem = additionalSections.find(item => item.href === "/qrcode");
                if (qrcodeItem) {
                    if (sections.footer.length <= 1) {
                        sections.footer.push(qrcodeItem);  // Add at the end if there are one or no items
                    } else {
                        const middleIndex = Math.floor(sections.footer.length / 2);
                        sections.footer.splice(middleIndex, 0, qrcodeItem);  // Insert in the middle
                    }
                }
            }

            // Concatenate current sections with additionalSections for sections.sections
            sections.sections = currentSections.concat(additionalSections);
            // Dispatch the updated sections
            dispatch({ type: 'sections', payload: sections });

            return () => {
                newSocket.disconnect();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <AppContext.Provider value={{
            appState, dispatch, socket, updatePageDataNetworking, setUpdatePageDataNetworking, modalConfirmation, setModalConfirmation, totalContacts, setTotalContacts,
            confirmationProps, setConfirmationProps, handleExit, updateNotifications, setUpdateNotifications, clearNotification, setClearNotification, clearNotificationID,
            setClearNotificationID, isDebouncing, timerRef, scrollContainerRef, loading, setLoading
        }}>
            {children}
            {loading && <div className="loadingScreenProtection">
                <Oval
                    visible={true}
                    height="60"
                    width="60"
                    secondaryColor={'rgba(255, 255,255, 1)'}
                    color={dataApplication && dataApplication.customizacao.primaryColor}
                    ariaLabel="oval-loading"
                />
            </div>}
        </AppContext.Provider>
    );
};

// Additional objects for the footer
const additionalSections = [
    {
        "href": "/",
        "icon": "faHome",
        "title": "Página inicial",
        "displayOnFooter": true
    },
    {
        "href": "/qrcode",
        "icon": "faQrcode",
        "title": "QR Code",
        "displayOnFooter": true
    },
    {
        "href": "/menu",
        "icon": "faBars",
        "title": "Menu",
        "displayOnFooter": true
    },
    {
        "href": "/perfil",
        "icon": "",
        "title": "Perfil",
        "displayOnFooter": false
    },
    {
        "href": "/suporte",
        "icon": "",
        "title": "Suporte",
        "displayOnFooter": false
    },
    {
        "href": "/links",
        "icon": "",
        "title": "Links",
        "displayOnFooter": false
    },
    {
        "href": "/notificacoes",
        "icon": "",
        "title": "Notificações",
        "displayOnFooter": false
    }
];



// Additional objects for sections validations
export {
    AppContext,
    AppProvider
};