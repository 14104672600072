import React from 'react'
import { motion, LayoutGroup, } from 'framer-motion'
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useSidebar from "../hooks/useSidebar";
import { useDebounce } from '../components/RouteDebouncer.js';
import { useAppState } from "../hooks/useApp";
import { getIcon } from '../components/Functions.js'

const Footer = () => {
    const { appState } = useAppState();

    if (!appState.sections || appState.sections.footer.length === 0) {
        return ""
    }

    return (
        <footer className="footer">
            <ul className="nav nav-pills nav-fill">
                <LayoutGroup id="a">
                    {appState.sections.footer.map((item, i) => (
                        <FooterNavItem key={'navItem' + i} item={item} />
                    ))}
                </LayoutGroup>
            </ul>
        </footer>
    )
}

const FooterNavItem = ({ item }) => {
    const { appState } = useAppState();
    const { isOpen, setIsOpen } = useSidebar();
    const { pathname } = useLocation()
    const navigate = useNavigate();
    const location = useLocation();
    let sectionB = appState.sections.footer.find(item => item.href === location.pathname);  // Add to the end

    const debouncedNavigate = useDebounce((path, props = undefined) => {
        if (props.href === '/links') {
            navigate(`${path}${location.search}`, { state: { item: props } });
        } else {
            navigate(`${path}${location.search}`);
        }
    }, 250);


    const icon = getIcon(item.icon);

    const handleSidebar = () => {
        if (isOpen) {
            if (window.innerWidth <= 769) {
                setIsOpen(!isOpen);
            }
        }
    };
    return (
        <motion.li className="nav-item" onClick={() => {
            if (item.href === '/menu') {
                setIsOpen((prevState) => !prevState);
            } else {
                debouncedNavigate(item.href, item)
                handleSidebar()
            }
        }}>
            <span className={(item.href === pathname || (!sectionB && item.href === '/menu' && item.href !== '/notifications')) ? 'active' : ''}>
                <FontAwesomeIcon icon={icon} size="lg" />
                {(item.href === pathname || (!sectionB && item.href === '/menu' && item.href !== '/notifications')) && <motion.div
                    className="underline"
                    layoutId="underline"
                />}
            </span>
        </motion.li>
    )
}

export default Footer